import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pp-caption',
  template: '<p class="box-mandatory"><ng-content></ng-content></p>',
  styles: [`
  .box-mandatory {
    text-align: end;
    font-size: 0.75rem;
    font-style: oblique;
    color: #6c6969;
  }
  `]
})

export class CaptionComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
