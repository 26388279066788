import { Component, OnInit } from '@angular/core';
import { FormFieldCheckboxComponent } from '../form-field-checkbox.component';

@Component({
  selector: 'pp-label-checkbox',
  templateUrl: 'label-checkbox.component.html',
  styleUrls: ['label-checkbox.component.css']
})
export class LabelCheckboxComponent implements OnInit {

  constructor(public formField: FormFieldCheckboxComponent) { }

  ngOnInit() {
  }

}
