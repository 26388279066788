import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pp-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})

export class ErrorComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
