import { Component, ViewChild, OnInit, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { SipsRequest } from '@app/core/services/financial-engine/models/sipsRequest';
import { SipsResponse } from './sips-response.model';

@Component({
  selector: 'pp-sips',
  templateUrl: './sips.component.html',
  styleUrls: ['./sips.component.css']
})
export class SipsComponent implements OnInit {

  @ViewChild('sipsForm', { static: true }) sipsForm: ElementRef;
  @ViewChild('data', { static: true }) data: ElementRef;
  @ViewChild('encode', { static: true }) encode: ElementRef;
  @ViewChild('version', { static: true }) version: ElementRef;
  @ViewChild('seal', { static: true }) seal: ElementRef;

  @Input() sipsRequest: SipsRequest;
  @Output() receive = new EventEmitter<SipsResponse>();

  amount: number;

  constructor() {
  }

  @HostListener('window:message', ['$event'])
  onMessage(e: any) {
    if (typeof e.data === 'string') {
      const responseCode = e.data.split(';')[0];
      const paymentToken = e.data.split(';')[1];
      this.receive.emit({ responseCode, paymentToken });
    }
  }

  ngOnInit() {
    this.sipsForm.nativeElement.action = this.sipsRequest.url;
    this.data.nativeElement.value = this.sipsRequest.data;
    if (this.getSipsParameterValue("amount") != null) {
      this.amount = +this.getSipsParameterValue("amount") / 100;
    }
    this.encode.nativeElement.value = this.sipsRequest.encode;
    this.version.nativeElement.value = this.sipsRequest.interfaceVersion;
    this.seal.nativeElement.value = this.sipsRequest.seal;

    this.sipsForm.nativeElement.submit();
  }

  private getSipsParameterValue(sipsParameter: string) {

    let result: string[];

    const regex = new RegExp(sipsParameter + "\=([^\|]+)");
    if (result = regex.exec(atob(this.sipsRequest.data))) {
      return result[1];
    } else {
      return null;
    }

  }

}
