import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateIfExist'
})
export class TranslateIfExistPipe implements PipeTransform {

  constructor(private translate: TranslateService) { }

  transform(key: string, defaultValue: string): string {
    const translateKey: string = this.translate.instant(key);
    // if failed to translate return defaultValue else the translation
    return translateKey.startsWith(key) ? defaultValue : translateKey;
  }
}
