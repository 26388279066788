import { Component, OnInit, Input } from '@angular/core';

enum Status {
  DANGER = 'DANGER',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  DEFAULT = 'DEFAULT'
}
@Component({
  selector: 'pp-status-label',
  templateUrl: './status-label.component.html',
  styleUrls: ['./status-label.component.css']
})
export class StatusLabelComponent implements OnInit {

  @Input()
  status = Status;

  constructor() { }

  ngOnInit() {
  }

}
