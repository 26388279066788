import { Component, OnInit, Inject, ElementRef, HostListener, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import 'rxjs/add/operator/filter';
import { KeycloakService } from 'keycloak-angular';
import { Location, DOCUMENT } from '@angular/common';
import { AuthentificationService } from './core/services/mobility-engine/authentification.service';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { MobilityAccountControllerService } from './core/services/mobility-engine/api';
import { AbstractOnDestroyComponent } from './core/abstract-on-destroy-component/abstract-on-destroy-component';
import { takeUntil } from 'rxjs/operators';
import { SimpleModalService } from 'ngx-simple-modal';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from './core/services/util.service';
import { SimpleInformationModalComponent } from './shared/components/simple-information-modal/simple-information-modal.component';
import { MobilityAccountResponse } from './core/model/models';
import { MobilityAccountStatus } from './core/model/mobility-account/enum/mobility-account-status.enum';
import { MaintenanceService } from './core/services/maintenance/maintenance.service';
import { MaintenanceStatus } from './core/services/maintenance/models/maintenance-config.model';

// var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = 0;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends AbstractOnDestroyComponent implements OnInit, AfterViewInit {

  // to hide header and footer in maintenance
  maintenanceInProgress: boolean = false;

  // to display banner
  maintenancePlanned: boolean = false;

  userData: MobilityAccountResponse;
  userUnavailable = false;

  @ViewChild('container', { static: true }) contentDiv: ElementRef;
  @HostListener('window:resize', ['$event'])

  onResize(_event: any) {
    this.adjustMainContainer();
  }

  constructor(
    private titleService: Title,
    private renderer: Renderer2,
    private router: Router,
    private keycloakService: KeycloakService,
    @Inject(DOCUMENT) private document: HTMLDocument,
    private element: ElementRef,
    public location: Location,
    private authenticationService: AuthentificationService,
    private mobilityAccountController: MobilityAccountControllerService,
    private simpleModalService: SimpleModalService,
    private translateService: TranslateService,
    private utilService: UtilService,
    private maintenanceService: MaintenanceService
  ) {
    super();
  }

  @HostListener('window:scroll', ['$event'])
  hasScrolled() {

    const st = window.pageYOffset;
    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) {
      return;
    }

    const navbar = document.getElementsByTagName('nav')[0];

    // If they scrolled down and are past the navbar, add class .headroom--unpinned.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight && navbar) {
      // Scroll Down
      if (navbar.classList.contains('headroom--pinned')) {
        navbar.classList.remove('headroom--pinned');
        navbar.classList.add('headroom--unpinned');
      }
      // $('.navbar.headroom--pinned').removeClass('headroom--pinned').addClass('headroom--unpinned');
    } else {
      // Scroll Up
      //  $(window).height()
      if (st + window.innerHeight < document.body.scrollHeight && navbar) {
        // $('.navbar.headroom--unpinned').removeClass('headroom--unpinned').addClass('headroom--pinned');
        if (navbar.classList.contains('headroom--unpinned')) {
          navbar.classList.remove('headroom--unpinned');
          navbar.classList.add('headroom--pinned');
        }
      }
    }

    lastScrollTop = st;
  }

  ngOnInit() {
    this.initMaintenance();
    const navbar: HTMLElement = this.element.nativeElement.children[0].children[0];
    this.userData = this.authenticationService.loggedUser$.getValue();

    if (this.userData && (this.userData.mobilityAccountId < 0
      || this.userData.status === MobilityAccountStatus.CANCELLED
      || this.userData.status === MobilityAccountStatus.TERMINATED)) {
      this.userUnavailable = true;
      this.utilService.defaultLanguage.pipe(takeUntil(this.unsubscribe)).subscribe(lang => {
        this.translateService.use(lang);
      });

      let options;

      if (this.userData.mobilityAccountId === -503 ) {
        this.userUnavailable = false;
        options = {
          title: 'ACCOUNT.MESSAGE.ERROR.TITLE',
          message: 'ERROR.INTERNAL_SERVER',
          closeButton: 'ACCOUNT.MESSAGE.ERROR.CLOSE_BUTTON'
        };
      } else if (this.userData.status === MobilityAccountStatus.CANCELLED || this.userData.status === MobilityAccountStatus.TERMINATED ) {
        options = {
          title: 'ACCOUNT.MESSAGE.DELETED.TITLE',
          message: 'ACCOUNT.MESSAGE.DELETED.MESSAGE',
          closeButton: 'ACCOUNT.MESSAGE.DELETED.CLOSE_BUTTON'
        };
      } else {
        options = {
          title: 'ACCOUNT.MESSAGE.ERROR.TITLE',
          message: 'ACCOUNT.MESSAGE.ERROR.MESSAGE',
          closeButton: 'ACCOUNT.MESSAGE.ERROR.CLOSE_BUTTON'
        };
      }

      if (options && !this.maintenanceInProgress) {
        this.simpleModalService.addModal(SimpleInformationModalComponent, options).subscribe(isOk => {
          if (isOk) {
            const baseUrl = location.origin;
            this.keycloakService.logout(baseUrl).then(() => {});
            window.sessionStorage.clear();
          }
        });
      }
    }

    // activate account if the account status still initialized
    if (this.userData && this.userData.status && this.userData.status === MobilityAccountStatus.INITIALIZED ) {
      this.mobilityAccountController.activateMobilityAccountUsingGET(this.userData.mobilityAccountId).pipe(takeUntil(this.unsubscribe)).subscribe();

      this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
        if (window.outerWidth > 991) {
          window.document.children[0].scrollTop = 0;
        } else {
          window.document.activeElement.scrollTop = 0;
        }
        this.renderer.listen('window', 'scroll', (event) => {
          const number = window.scrollY;
          if (navbar) {
            if (number > 150 || window.pageYOffset > 150) {
              // add logic
              navbar.classList.add('headroom--not-top');
            } else {
              // remove logic
              navbar.classList.remove('headroom--not-top');
            }
          }
        });
      });
    }

    this.hasScrolled();
    /*
     * Set application favicon dynamicaly
     * You sould add 'appFavicon' id in the index.html
    */
    this.document.getElementById('appFavicon').setAttribute('href', environment.passenger.application.favicon);
    /* Set application title dynamicaly */
    this.titleService.setTitle(environment.passenger.application.title);
  }

  ngAfterViewInit(): void {
    this.adjustMainContainer();
  }

  private adjustMainContainer() {
    const windowSize = window.innerHeight - 50;
    this.contentDiv.nativeElement.style.minHeight = windowSize + 'px';
  }

  private initMaintenance() {
    this.maintenanceService.nextMaintenanceStatus(MaintenanceStatus.INITIALIZE);
    this.maintenanceInProgress = this.maintenanceService.getMaintenanceActivedSubject();
    this.maintenancePlanned = this.maintenanceService.getShowBannerSubject();
  }
}
