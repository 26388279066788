import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pp-checklist-item',
  templateUrl: './checklist-item.component.html',
  styleUrls: ['./checklist-item.component.css']
})
export class ChecklistItemComponent implements OnInit {

  @Input() checked: boolean;
  @Output() checkedChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  selectItem() {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }

}
