/**
 * Mobility Engine API
 * Service that exposes REST API to access to Mobility Engine.
 *
 * OpenAPI spec version: 1.15.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { PageCustomerRequestDTO, CustomerRequestDTO, CommentDTO ,AdminDTO} from '@app/core/model/models';

import { environment }                                       from '@env/environment';
import { Configuration }                                     from '../configuration';
var moment = require('moment')
@Injectable()
export class CustomerRequestControllerService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {
        this.basePath = environment.passenger.api.mobilityEngine;
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createCustomerRequest
     * 
     * @param customerRequest customerRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomerRequestUsingPOST(customerRequest: CustomerRequestDTO, observe?: 'body', reportProgress?: boolean): Observable<CustomerRequestDTO>;
    public createCustomerRequestUsingPOST(customerRequest: CustomerRequestDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerRequestDTO>>;
    public createCustomerRequestUsingPOST(customerRequest: CustomerRequestDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerRequestDTO>>;
    public createCustomerRequestUsingPOST(customerRequest: CustomerRequestDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerRequest === null || customerRequest === undefined) {
            throw new Error('Required parameter customerRequest was null or undefined when calling createCustomerRequestUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerRequestDTO>(`${this.basePath}/v1/customerrequests`,
            customerRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * readCustomerRequestForExternalById
     * 
     * @param customerRequestId customerRequestId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readCustomerRequestForExternalByIdUsingGET(customerRequestId: number, observe?: 'body', reportProgress?: boolean): Observable<CustomerRequestDTO>;
    public readCustomerRequestForExternalByIdUsingGET(customerRequestId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerRequestDTO>>;
    public readCustomerRequestForExternalByIdUsingGET(customerRequestId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerRequestDTO>>;
    public readCustomerRequestForExternalByIdUsingGET(customerRequestId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerRequestId === null || customerRequestId === undefined) {
            throw new Error('Required parameter customerRequestId was null or undefined when calling readCustomerRequestForExternalByIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CustomerRequestDTO>(`${this.basePath}/v1/customerrequests/${encodeURIComponent(String(customerRequestId))}/_external`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * readCustomerRequestsForExternal
     * 
     * @param customerReference customerReference
     * @param status the customerRequest status (OPEN, ASSIGNED, CLOSED)
     * @param date the customerRequest date (format must be YYYY-MM-DDTHH:mm:ss.uuuZ)
     * @param sujet the customerRequest subject
     * @param customerRequestId the customerRequest's identifiant
     * @param page Results page you want to retrieve (0..N)
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readCustomerRequestsForExternalUsingGET(customerReference?: string, status?: Array<'OPEN' | 'ASSIGNED' | 'CLOSED'>, date?: Date, sujet?: string, customerRequestId?: number, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageCustomerRequestDTO>;
    public readCustomerRequestsForExternalUsingGET(customerReference?: string, status?: Array<'OPEN' | 'ASSIGNED' | 'CLOSED'>, date?: Date, sujet?: string, customerRequestId?: number, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageCustomerRequestDTO>>;
    public readCustomerRequestsForExternalUsingGET(customerReference?: string, status?: Array<'OPEN' | 'ASSIGNED' | 'CLOSED'>, date?: Date, sujet?: string, customerRequestId?: number, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageCustomerRequestDTO>>;
    public readCustomerRequestsForExternalUsingGET(customerReference?: string, status?: Array<'OPEN' | 'ASSIGNED' | 'CLOSED'>, date?: Date, sujet?: string, customerRequestId?: number, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
  
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        if (customerRequestId !== undefined && customerRequestId !== null) {
            queryParameters = queryParameters.set('customerRequestId', <any>customerRequestId);
        }

        if (typeof date !== 'undefined' && date !== null && moment(date).isValid()) {
            let since = moment(date).format("YYYY-MM-DDTHH:mm:ss");
            let until = moment(date).add(1, 'd').format("YYYY-MM-DDTHH:mm:ss");
            queryParameters = queryParameters.set('since', <any>since);
            queryParameters = queryParameters.set('until', <any>until);
        }

        if (typeof sujet !== 'undefined' && sujet !== null) {
            queryParameters = queryParameters.set('customerRequestSubject', <any>sujet);
        }

        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageCustomerRequestDTO>(`${this.basePath}/v1/customers/${encodeURIComponent(String(customerReference))}/customerrequests/_external`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * readCustomerRequestsForExternal
     * 
     * @param customerReference customerReference
     * @param customerRequestId customerRequestId
     * @param customerRequestSubject customerRequestSubject
     * @param page Results page you want to retrieve (0..N)
     * @param since since
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param status status
     * @param until until
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readCustomerRequestsForExternalUsingGET1(customerReference?: string, customerRequestId?: number, customerRequestSubject?: string, page?: number, since?: Date, size?: number, sort?: Array<string>, status?: Array<'OPEN' | 'ASSIGNED' | 'CLOSED'>, until?: Date, observe?: 'body', reportProgress?: boolean): Observable<PageCustomerRequestDTO>;
    public readCustomerRequestsForExternalUsingGET1(customerReference?: string, customerRequestId?: number, customerRequestSubject?: string, page?: number, since?: Date, size?: number, sort?: Array<string>, status?: Array<'OPEN' | 'ASSIGNED' | 'CLOSED'>, until?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageCustomerRequestDTO>>;
    public readCustomerRequestsForExternalUsingGET1(customerReference?: string, customerRequestId?: number, customerRequestSubject?: string, page?: number, since?: Date, size?: number, sort?: Array<string>, status?: Array<'OPEN' | 'ASSIGNED' | 'CLOSED'>, until?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageCustomerRequestDTO>>;
    public readCustomerRequestsForExternalUsingGET1(customerReference?: string, customerRequestId?: number, customerRequestSubject?: string, page?: number, since?: Date, size?: number, sort?: Array<string>, status?: Array<'OPEN' | 'ASSIGNED' | 'CLOSED'>, until?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerRequestId !== undefined && customerRequestId !== null) {
            queryParameters = queryParameters.set('customerRequestId', <any>customerRequestId);
        }
        if (customerRequestSubject !== undefined && customerRequestSubject !== null) {
            queryParameters = queryParameters.set('customerRequestSubject', <any>customerRequestSubject);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (since !== undefined && since !== null) {
            let date = moment(since).format("YYYY-MM-DDTHH:mm:ss");
            queryParameters = queryParameters.set('since', <any>date);            
            queryParameters = queryParameters.set('until', <any>moment(date).add(1, 'd').format("YYYY-MM-DDTHH:mm:ss"));
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }
        if (status) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageCustomerRequestDTO>(`${this.basePath}/v1/customers/${encodeURIComponent(String(customerReference))}/customerrequests/_external`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
