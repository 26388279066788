import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pp-helper',
  template: '<div class="pwd-help"><ng-content></ng-content></div>',
  styles: [`
    .pwd-help {
      margin: 1rem 0;
      border-radius: 20px;
      font-size: 0.9rem;
      width: 100%;
      color: #995C00;
      background-color: #FEEFB3;
      padding: 0.5rem 0.8rem;
    }
  `]
})

export class HelperComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
