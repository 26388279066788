export * from './accessMediaController.service';
import { AccessMediaControllerService } from './accessMediaController.service';
export * from './customerRequestController.service';
import { CustomerRequestControllerService } from './customerRequestController.service';
export * from './mobilityAccountController.service';
import { MobilityAccountControllerService } from './mobilityAccountController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export * from './sipsController.service';
import { SipsControllerService } from './sipsController.service';
export const APIS = [SipsControllerService, AccessMediaControllerService, CustomerRequestControllerService, MobilityAccountControllerService, UserControllerService];
