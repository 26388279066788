import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pp-ogone-message',
  template: `<ng-content></ng-content>`
})
export class OgoneMessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
