import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/Observable';
import { Configuration } from '../configuration';
import { AddressRequest, AddressResponse, MobilityAccountResponse, MobilityAccountRequest, MobilityAccountContactDetailsDTO, MobilityAccountIdentityDTO  } from '@app/core/model/models';

@Injectable()
export class MobilityAccountControllerService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {
        this.basePath = environment.passenger.api.mobilityEngine;
    }

    /**
     * Create addresses
     * Add addresses to a specific mobility account.
     * @param addressRequests Addresses to create
     * @param mobilityAccountId mobilityAccount identifier
     * @param actorReference actorReference
     * @param actorType actorType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAddressUsingPOST(addressRequests: Array<AddressRequest>, mobilityAccountId: number, actorReference?: string, actorType?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AddressResponse>>;
    public createAddressUsingPOST(addressRequests: Array<AddressRequest>, mobilityAccountId: number, actorReference?: string, actorType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AddressResponse>>>;
    public createAddressUsingPOST(addressRequests: Array<AddressRequest>, mobilityAccountId: number, actorReference?: string, actorType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AddressResponse>>>;
    public createAddressUsingPOST(addressRequests: Array<AddressRequest>, mobilityAccountId: number, actorReference?: string, actorType?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (addressRequests === null || addressRequests === undefined) {
            throw new Error('Required parameter addressRequests was null or undefined when calling createAddressUsingPOST.');
        }

        if (mobilityAccountId === null || mobilityAccountId === undefined) {
            throw new Error('Required parameter mobilityAccountId was null or undefined when calling createAddressUsingPOST.');
        }



        let headers = this.defaultHeaders;
        if (actorReference !== undefined && actorReference !== null) {
            headers = headers.set('actorReference', String(actorReference));
        }
        if (actorType !== undefined && actorType !== null) {
            headers = headers.set('actorType', String(actorType));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<AddressResponse>>(`${this.basePath}/v1/mobilityaccounts/${encodeURIComponent(String(mobilityAccountId))}/addresses`,
            addressRequests,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * Get MobilityAccount from origin and reference
     * Retrieve a Mobility account from origin and reference
     * @param userOrigin userOrigin
     * @param userReference userReference
     * @param actorReference actorReference
     * @param actorType actorType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readMobilityAccountFromOriginAndReferenceUsingGET(userOrigin: string, userReference: string, actorReference?: string, actorType?: string, observe?: 'body', reportProgress?: boolean): Observable<MobilityAccountResponse>;
    public readMobilityAccountFromOriginAndReferenceUsingGET(userOrigin: string, userReference: string, actorReference?: string, actorType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MobilityAccountResponse>>;
    public readMobilityAccountFromOriginAndReferenceUsingGET(userOrigin: string, userReference: string, actorReference?: string, actorType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MobilityAccountResponse>>;
    public readMobilityAccountFromOriginAndReferenceUsingGET(userOrigin: string, userReference: string, actorReference?: string, actorType?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (userOrigin === null || userOrigin === undefined) {
            throw new Error('Required parameter userOrigin was null or undefined when calling readMobilityAccountFromOriginAndReferenceUsingGET.');
        }

        if (userReference === null || userReference === undefined) {
            throw new Error('Required parameter userReference was null or undefined when calling readMobilityAccountFromOriginAndReferenceUsingGET.');
        }



        let headers = this.defaultHeaders;
        if (actorReference !== undefined && actorReference !== null) {
            headers = headers.set('actorReference', String(actorReference));
        }
        if (actorType !== undefined && actorType !== null) {
            headers = headers.set('actorType', String(actorType));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MobilityAccountResponse>(`${this.basePath}/v1/origin/${encodeURIComponent(String(userOrigin))}/reference/${encodeURIComponent(String(userReference))}/mobilityaccount`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get MobilityAccount
     * Retrieve a mobility account by ID
     * @param mobilityAccountId mobilityAccount identifier
     * @param actorReference actorReference
     * @param actorType actorType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readMobilityAccountUsingGET(mobilityAccountId: number, actorReference?: string, actorType?: string, observe?: 'body', reportProgress?: boolean): Observable<MobilityAccountResponse>;
    public readMobilityAccountUsingGET(mobilityAccountId: number, actorReference?: string, actorType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MobilityAccountResponse>>;
    public readMobilityAccountUsingGET(mobilityAccountId: number, actorReference?: string, actorType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MobilityAccountResponse>>;
    public readMobilityAccountUsingGET(mobilityAccountId: number, actorReference?: string, actorType?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (mobilityAccountId === null || mobilityAccountId === undefined) {
            throw new Error('Required parameter mobilityAccountId was null or undefined when calling readMobilityAccountUsingGET.');
        }



        let headers = this.defaultHeaders;
        if (actorReference !== undefined && actorReference !== null) {
            headers = headers.set('actorReference', String(actorReference));
        }
        if (actorType !== undefined && actorType !== null) {
            headers = headers.set('actorType', String(actorType));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MobilityAccountResponse>(`${this.basePath}/v1/mobilityaccounts/${encodeURIComponent(String(mobilityAccountId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * Register MobilityAccount
     * Register a mobility account.
     * @param mobilityAccountRequest MobilityAccount enrolment data
     * @param actorReference actorReference
     * @param actorType actorType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerMobilityAccountUsingPOST(mobilityAccountRequest: MobilityAccountRequest, actorReference?: string, actorType?: string, observe?: 'body', reportProgress?: boolean): Observable<MobilityAccountResponse>;
    public registerMobilityAccountUsingPOST(mobilityAccountRequest: MobilityAccountRequest, actorReference?: string, actorType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MobilityAccountResponse>>;
    public registerMobilityAccountUsingPOST(mobilityAccountRequest: MobilityAccountRequest, actorReference?: string, actorType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MobilityAccountResponse>>;
    public registerMobilityAccountUsingPOST(mobilityAccountRequest: MobilityAccountRequest, actorReference?: string, actorType?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

      if (mobilityAccountRequest === null || mobilityAccountRequest === undefined) {
        throw new Error('Required parameter mobilityAccountRequest was null or undefined when calling registerMobilityAccountUsingPOST.');
      }

      if (mobilityAccountRequest.mobilityAccountContactDetails.mobilityAccountPhoneNumber == '') {
        mobilityAccountRequest.mobilityAccountContactDetails.mobilityAccountPhoneNumber = null;
      }

      let headers = this.defaultHeaders;
      if (actorReference !== undefined && actorReference !== null) {
        headers = headers.set('actorReference', String(actorReference));
      }
      if (actorType !== undefined && actorType !== null) {
        headers = headers.set('actorType', String(actorType));
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = ['application/json'];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = ['application/json'];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.post<MobilityAccountResponse>(`${this.basePath}/v1/mobilityaccounts/enrolment`, mobilityAccountRequest,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      });
    }

    /**
     * Update Address
     * Update an address
     * @param addressId Address identifier
     * @param addressRequest Address to update
     * @param mobilityAccountId mobilityAccount identifier
     * @param actorReference actorReference
     * @param actorType actorType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAddressUsingPUT(addressId: number, addressRequest: AddressRequest, mobilityAccountId: number, actorReference?: string, actorType?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateAddressUsingPUT(addressId: number, addressRequest: AddressRequest, mobilityAccountId: number, actorReference?: string, actorType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateAddressUsingPUT(addressId: number, addressRequest: AddressRequest, mobilityAccountId: number, actorReference?: string, actorType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateAddressUsingPUT(addressId: number, addressRequest: AddressRequest, mobilityAccountId: number, actorReference?: string, actorType?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (addressId === null || addressId === undefined) {
            throw new Error('Required parameter addressId was null or undefined when calling updateAddressUsingPUT.');
        }

        if (addressRequest === null || addressRequest === undefined) {
            throw new Error('Required parameter addressRequest was null or undefined when calling updateAddressUsingPUT.');
        }

        if (mobilityAccountId === null || mobilityAccountId === undefined) {
            throw new Error('Required parameter mobilityAccountId was null or undefined when calling updateAddressUsingPUT.');
        }

        let headers = this.defaultHeaders;
        if (actorReference !== undefined && actorReference !== null) {
            headers = headers.set('actorReference', String(actorReference));
        }
        if (actorType !== undefined && actorType !== null) {
            headers = headers.set('actorType', String(actorType));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/v1/mobilityaccounts/${encodeURIComponent(String(mobilityAccountId))}/addresses/${encodeURIComponent(String(addressId))}`,
            addressRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update mobility account contact details
     * Update mobility account contact details
     * @param mobilityAccountContactDetailsDTO Mobility Account ContactDetails to update
     * @param mobilityAccountId MobilityAccount identifier to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMobilityAccountContactDetailsUsingPUT(mobilityAccountContactDetailsDTO: MobilityAccountContactDetailsDTO, mobilityAccountId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateMobilityAccountContactDetailsUsingPUT(mobilityAccountContactDetailsDTO: MobilityAccountContactDetailsDTO, mobilityAccountId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateMobilityAccountContactDetailsUsingPUT(mobilityAccountContactDetailsDTO: MobilityAccountContactDetailsDTO, mobilityAccountId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateMobilityAccountContactDetailsUsingPUT(mobilityAccountContactDetailsDTO: MobilityAccountContactDetailsDTO, mobilityAccountId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (mobilityAccountContactDetailsDTO === null || mobilityAccountContactDetailsDTO === undefined) {
            throw new Error('Required parameter mobilityAccountContactDetailsDTO was null or undefined when calling updateMobilityAccountContactDetailsUsingPUT.');
        }

        if (mobilityAccountId === null || mobilityAccountId === undefined) {
            throw new Error('Required parameter mobilityAccountId was null or undefined when calling updateMobilityAccountContactDetailsUsingPUT.');
        }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/v1/mobilityaccounts/${encodeURIComponent(String(mobilityAccountId))}/contactdetails`,
            mobilityAccountContactDetailsDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }



    /**
     * Update mobility account identity
     * Update mobility account identity
     * @param mobilityAccountId MobilityAccount identifier to update
     * @param mobilityAccountIdentityDTO Mobility Account Identity to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMobilityAccountIdentityUsingPUT(mobilityAccountId: number, mobilityAccountIdentityDTO: MobilityAccountIdentityDTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateMobilityAccountIdentityUsingPUT(mobilityAccountId: number, mobilityAccountIdentityDTO: MobilityAccountIdentityDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateMobilityAccountIdentityUsingPUT(mobilityAccountId: number, mobilityAccountIdentityDTO: MobilityAccountIdentityDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateMobilityAccountIdentityUsingPUT(mobilityAccountId: number, mobilityAccountIdentityDTO: MobilityAccountIdentityDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (mobilityAccountId === null || mobilityAccountId === undefined) {
            throw new Error('Required parameter mobilityAccountId was null or undefined when calling updateMobilityAccountIdentityUsingPUT.');
        }

        if (mobilityAccountIdentityDTO === null || mobilityAccountIdentityDTO === undefined) {
            throw new Error('Required parameter mobilityAccountIdentityDTO was null or undefined when calling updateMobilityAccountIdentityUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/v1/mobilityaccounts/${encodeURIComponent(String(mobilityAccountId))}/identity`,
            mobilityAccountIdentityDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update mobility account identity
     * Update mobility account identity
     * @param mobilityAccountId MobilityAccount identifier to update
     * @param mobilityAccountIdentityDTO Mobility Account Identity to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMobilityAccountDetails(mobilityAccountId: number, mobilityAccountDetailsDTO: MobilityAccountContactDetailsDTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateMobilityAccountDetails(mobilityAccountId: number, mobilityAccountDetailsDTO: MobilityAccountContactDetailsDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateMobilityAccountDetails(mobilityAccountId: number, mobilityAccountDetailsDTO: MobilityAccountContactDetailsDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateMobilityAccountDetails(mobilityAccountId: number, mobilityAccountDetailsDTO: MobilityAccountContactDetailsDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (mobilityAccountId === null || mobilityAccountId === undefined) {
            throw new Error('Required parameter mobilityAccountId was null or undefined when calling updateMobilityAccountIdentityUsingPUT.');
        }

        if (mobilityAccountDetailsDTO === null || mobilityAccountDetailsDTO === undefined) {
            throw new Error('Required parameter mobilityAccountIdentityDTO was null or undefined when calling updateMobilityAccountIdentityUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (mobilityAccountDetailsDTO.mobilityAccountPhoneNumber == ''){
            mobilityAccountDetailsDTO.mobilityAccountPhoneNumber = null;
        }

        return this.httpClient.put<any>(`${this.basePath}/v1/mobilityaccounts/${encodeURIComponent(String(mobilityAccountId))}/contactdetails`,
        mobilityAccountDetailsDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Activate MobilityAccount
     * Activate a mobility account by ID
     * @param mobilityAccountId mobilityAccount identifier
     * @param actorReference actorReference
     * @param actorType actorType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateMobilityAccountUsingGET(mobilityAccountId: number, actorReference?: string, actorType?: string, observe?: 'body', reportProgress?: boolean): Observable<MobilityAccountResponse>;
    public activateMobilityAccountUsingGET(mobilityAccountId: number, actorReference?: string, actorType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MobilityAccountResponse>>;
    public activateMobilityAccountUsingGET(mobilityAccountId: number, actorReference?: string, actorType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MobilityAccountResponse>>;
    public activateMobilityAccountUsingGET(mobilityAccountId: number, actorReference?: string, actorType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mobilityAccountId === null || mobilityAccountId === undefined) {
            throw new Error('Required parameter mobilityAccountId was null or undefined when calling activateMobilityAccountUsingGET.');
        }



        let headers = this.defaultHeaders;
        if (actorReference !== undefined && actorReference !== null) {
            headers = headers.set('actorReference', String(actorReference));
        }
        if (actorType !== undefined && actorType !== null) {
            headers = headers.set('actorType', String(actorType));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MobilityAccountResponse>(`${this.basePath}/v1/mobilityaccounts/${encodeURIComponent(String(mobilityAccountId))}/activate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


}
