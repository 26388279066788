import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pp-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.css']
})
export class EmptyListComponent implements OnInit {
  @Input() message: string;
  @Input() withIcon = true;
  @Input() withButton = true;
  @Input() buttonTitle: string;
  @Input() customClass: string;
  @Output() clickAction: EventEmitter<Boolean> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  callback = () => {
    this.clickAction.emit(true);
  }

}
