import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppAuthGuard } from './core/guards/app-auth-guard';
import { AppInternalSupportGuard } from './core/guards/app-internal-support-guard';


const routes: Routes = [
  {
    path: 'activities',
    canActivate: [AppAuthGuard],
    loadChildren: () => import('./modules/activity/activity.module').then(mod => mod.ActivityModule)
  },
  {
    path: 'requests',
    canActivate: [AppAuthGuard, AppInternalSupportGuard],
    loadChildren: () => import('./modules/customerrequests/customerrequests.module').then(mod => mod.CustomerRequestsModule)
  },
  {
    path: 'payments',
    canActivate: [AppAuthGuard],
    loadChildren: () => import('./modules/payments/payments.module').then(mod => mod.PaymentsModule)
  },
  {
    path: 'supports',
    canActivate: [AppAuthGuard],
    loadChildren: () => import('./modules/supports/supports.module').then(mod => mod.SupportsModule)
  },
  {
    path: 'users',
    canActivate: [AppAuthGuard],
    loadChildren: () => import('./modules/user/user.module').then(mod => mod.UserModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./modules/maintenance/maintenance.module').then(mod => mod.MaintenanceModule)
  },
  { path: '',   redirectTo: 'activities', pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
