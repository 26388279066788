import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: any, pattern: string = 'EEEE dd LLLL yyyy'): any {

    const currentLang = this.translateService.currentLang;
    const datePipe: DatePipe = new DatePipe(currentLang);

    if (currentLang === 'en') {
      if (pattern === 'dd MMM') {
        pattern = 'MMM dd';
      } else if (pattern === 'd/M/yy') {
        pattern = 'M/d/yy';
      } else if (pattern === 'dd MMM HH:mm:ss') {
        pattern = 'MMM dd HH:mm:ss';
      } else if (pattern === 'dd MMM HH:mm') {
        pattern = 'MMM dd HH:mm';
      } else if (pattern === 'dd/MM/yyyy HH:mm') {
        pattern = 'MM/dd/yyyy HH:mm';
      } else {
        pattern = 'EEEE, LLLL dd, yyyy';
      }
    }

    return datePipe.transform(value, pattern);
  }
}
