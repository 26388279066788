import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '@env/environment';
import { NgProgressModule } from 'ngx-progressbar';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { SimpleModalModule } from 'ngx-simple-modal';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AppInternalSupportGuard } from './core/guards/app-internal-support-guard';
import { MobilityAccountStatus } from './core/model/mobility-account/enum/mobility-account-status.enum';
import { MobilityAccountResponse } from './core/model/models';
import { SafePipe } from './core/pipe/safe-html';
import { ConfigurationService } from './core/services/configuration.service';
import { AccessMediaControllerService, MobilityAccountControllerService } from './core/services/mobility-engine/api';
import { AuthentificationService } from './core/services/mobility-engine/authentification.service';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { SharedModule } from './shared/shared.module';
import { TenantService } from './core/services/tenant/tenant.service';
import { TenantInterceptor } from './core/services/tenant/tenant.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { MaintenanceInterceptor } from './core/services/maintenance/maintenance.interceptor';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    SafePipe
  ],
  imports: [
    KeycloakAngularModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgProgressModule.withConfig({
      spinner: false,
      color: '#1abb9c',
      thick: true
    }),
    ToastrModule.forRoot({ positionClass: 'toast-bottom-right' }),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    SimpleModalModule.forRoot({ container: 'modal-container' }),
    SharedModule,
  ],

  providers: [
    KeycloakService,
    { provide: LOCALE_ID, useValue: "fr-FR" }, //replace "en-US" with your locale
    MobilityAccountControllerService,
    AccessMediaControllerService,
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      multi: true,
      deps: [KeycloakService, ConfigurationService, AuthentificationService, HttpClient, TenantService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true
    },
    DatePipe,
    AppInternalSupportGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?cacheBuster=' + environment.common.version);
}

export function init(keycloak: KeycloakService, configurationService: ConfigurationService, authentificationService: AuthentificationService): () => Promise<any> {
  return async (): Promise<any> => {
    // Fetch local configuration
    await configurationService.initConfiguration().toPromise();
    // Fetch remote configuration
    if (environment.passenger.multitenant.enabled && !environment.passenger.multitenant.useLocaly)
    {
      await configurationService.fetchRemoteConfiguration().toPromise();
    }

    await keycloak.init({
      config: environment.passenger.keycloak,
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false
      },
      bearerExcludedUrls: [
        '/users/registration',
        '/maintenance',
        '/assets',
        '/v1/realms/' + environment.passenger.keycloak.realm + '/clients/' + environment.passenger.keycloak.clientId + '/users',
        '/v1/mobilityaccounts/enrolment',
        '/users/' + (environment.passenger.application.footer.cgu.urlPath || 'cgu'),
        '/users/' + (environment.passenger.application.footer.faq.urlPath || 'faq'),
        '/users/' + (environment.passenger.application.footer.legalNotice.urlPath || 'legal-notice'),
        '/users/application-config'
      ]
    });

    await configurationService.initLanguage();
    await configurationService.addCustomTranslation();

    if (await keycloak.isLoggedIn()) {
      await authentificationService.refreshLoggedUser().toPromise().catch((error: HttpErrorResponse) => {
        let userDeleted: MobilityAccountResponse;

        if (error && error.status === 503) {
          // MAM or ME is down
          userDeleted = { mobilityAccountId: -503 };
        } else if (error && error.error?.code === 403 && (error.error?.message.includes('CANCELLED') || error.error?.message.includes('TERMINATED')) ) {
          // The account is being deleted
          userDeleted = { status: MobilityAccountStatus.CANCELLED };
        } else {
          // An error occured
          userDeleted = { mobilityAccountId: -1 };
        }

        authentificationService.loggedUser$.next(userDeleted);
        return false;
      });
    }

    return true;
  };

}
