import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  displayFaq: boolean = environment.passenger.application.footer.faq.enabled;
  displayCgu: boolean = environment.passenger.application.footer.cgu.enabled;
  displayLegalNotice: boolean = environment.passenger.application.footer.legalNotice.enabled;

  faqUrlPath: string = environment.passenger.application.footer.faq.urlPath || 'faq';
  cguUrlPath: string = environment.passenger.application.footer.cgu.urlPath || 'cgu';
  legalNoticeUrlPath: string = environment.passenger.application.footer.legalNotice.urlPath || 'legal-notice';
}
