/**
 * Mobility Engine API
 * Service that exposes REST API to access to Mobility Engine.
 *
 * OpenAPI spec version: 1.15.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { environment }                                       from '@env/environment';
import { Configuration }                                     from '../configuration';
import { type } from 'os';


@Injectable()
export class SipsControllerService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {
        this.basePath = environment.passenger.api.financialEngine;
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Read validations by  filters
     * @param actorReference actorReference
     * @param actorType actorType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readValidationUsingGET(accessMediaId?: string, currencyCode?: string, customerLanguage?: string, normalReturnUrl?: string, orderChannel?: string, templateName?: string, actorReference?: string, actorType?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public readValidationUsingGET(accessMediaId?: string, currencyCode?: string, customerLanguage?: string, normalReturnUrl?: string, orderChannel?: string, templateName?: string, actorReference?: string, actorType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public readValidationUsingGET(accessMediaId?: string, currencyCode?: string, customerLanguage?: string, normalReturnUrl?: string, orderChannel?: string, templateName?: string, actorReference?: string, actorType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public readValidationUsingGET(accessMediaId?: string, currencyCode?: string, customerLanguage?: string, normalReturnUrl?: string, orderChannel?: string, templateName?: string, actorReference?: string, actorType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (accessMediaId !== undefined && accessMediaId !== null) {
            queryParameters = queryParameters.set('accessMediaId', <any>accessMediaId);
        }
        if (currencyCode !== undefined && currencyCode !== null) {
            queryParameters = queryParameters.set('currencyCode', <any>currencyCode);
        }
        if (customerLanguage !== undefined && customerLanguage !== null) {
            queryParameters = queryParameters.set('customerLanguage', <any>customerLanguage);
        }
        if (normalReturnUrl !== undefined && normalReturnUrl !== null) {
            queryParameters = queryParameters.set('normalReturnUrl', <any>normalReturnUrl);
        }
        if (orderChannel !== undefined && orderChannel !== null) {
            queryParameters = queryParameters.set('orderChannel', <any>orderChannel);
        }
        if (templateName !== undefined && templateName !== null) {
            queryParameters = queryParameters.set('templateName', <any>templateName);
        }

        let headers = this.defaultHeaders;
        if (actorReference !== undefined && actorReference !== null) {
            headers = headers.set('actorReference', String(actorReference));
        }
        if (actorType !== undefined && actorType !== null) {
            headers = headers.set('actorType', String(actorType));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<any>>(`${this.basePath}/api/payment-gateway/v1/sips/parameters/validation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public readAuthorizationUsingGET(currencyCode?: string, customerLanguage?: string, normalReturnUrl?: string, orderChannel?: string, templateName?: string, cardIdentificationToken?: string, transactionId?: string, cardPaymentToken?: string, actorReference?: string, actorType?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public readAuthorizationUsingGET(currencyCode?: string, customerLanguage?: string, normalReturnUrl?: string, orderChannel?: string, templateName?: string, cardIdentificationToken?: string, transactionId?: string, cardPaymentToken?: string, actorReference?: string, actorType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public readAuthorizationUsingGET(currencyCode?: string, customerLanguage?: string, normalReturnUrl?: string, orderChannel?: string, templateName?: string, cardIdentificationToken?: string, transactionId?: string, cardPaymentToken?: string, actorReference?: string, actorType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public readAuthorizationUsingGET(currencyCode?: string, customerLanguage?: string, normalReturnUrl?: string, orderChannel?: string, templateName?: string, cardIdentificationToken?: string, transactionId?: string, cardPaymentToken?: string, actorReference?: string, actorType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (currencyCode !== undefined && currencyCode !== null) {
            queryParameters = queryParameters.set('currencyCode', <any>currencyCode);
        }
        if (customerLanguage !== undefined && customerLanguage !== null) {
            queryParameters = queryParameters.set('customerLanguage', <any>customerLanguage);
        }
        if (normalReturnUrl !== undefined && normalReturnUrl !== null) {
            queryParameters = queryParameters.set('normalReturnUrl', <any>normalReturnUrl);
        }
        if (orderChannel !== undefined && orderChannel !== null) {
            queryParameters = queryParameters.set('orderChannel', <any>orderChannel);
        }
        if (templateName !== undefined && templateName !== null) {
            queryParameters = queryParameters.set('templateName', <any>templateName);
        }
        if (cardIdentificationToken !== undefined && cardIdentificationToken !== null) {
            queryParameters = queryParameters.set('cardIdentificationToken', <any>cardIdentificationToken);
        }
        if (transactionId !== undefined && transactionId !== null) {
            queryParameters = queryParameters.set('transactionId', <any>transactionId);
        }

        if (cardPaymentToken !== undefined && cardPaymentToken !== null) {
            queryParameters = queryParameters.set('cardPaymentToken', <any>cardPaymentToken);
        }

        let headers = this.defaultHeaders;
        if (actorReference !== undefined && actorReference !== null) {
            headers = headers.set('actorReference', String(actorReference));
        }
        if (actorType !== undefined && actorType !== null) {
            headers = headers.set('actorType', String(actorType));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<any>>(`${this.basePath}/api/payment-gateway/v1/sips/parameters/authorization`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
