import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pp-message',
  templateUrl: 'message.component.html',
  styleUrls: ['message.component.css']
})
export class MessageComponent implements OnInit {

  @Input() icon: string;

  constructor() { }

  ngOnInit() { }
}
