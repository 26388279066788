import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
/**
 * Pipe for making Tri in an array
 * @description you can use in html page like this : *ngFor=let element of arrays | orderBy:'field'"
 * @author A686397
 */
export class OrderByPipe  implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
