import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, map, single } from "rxjs/operators";
import { Configuration } from "../configuration";
import { MaintenanceConfigUI, MaintenanceStatus } from "./models/maintenance-config.model";

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  MAINTENANCE_LOCAL_ITEM = 'MAINTENANCE_CONFIG';

  baseUrl: string = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  maintenanceActived: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showBanner: BehaviorSubject<boolean> = new BehaviorSubject(false);
  maintenanceStatus: BehaviorSubject<MaintenanceStatus> = new BehaviorSubject(MaintenanceStatus.INITIALIZE);

  constructor(private httpClient: HttpClient) {
    this.init();
  }

  private init() {
    this.baseUrl = environment.passenger.api.gateway;
  }

  /***********
   * SUBJECT *
   ***********/

  nextMaintenanceActivedSubject(value: boolean) {
    this.maintenanceActived.next(value);
  }

  getMaintenanceActivedSubject(): boolean {
    return this.maintenanceActived.value;
  }

  nextShowBannerSubject(value: boolean) {
    this.showBanner.next(value);
  }

  getShowBannerSubject(): boolean {
    return this.showBanner.value;
  }

  nextMaintenanceStatus(value: MaintenanceStatus) {
    return this.maintenanceStatus.next(value);
  }

  getMaintenanceStatus(): MaintenanceStatus {
    return this.maintenanceStatus.value;
  }

  /****************
   * HTTP REQUEST *
   ****************/

  getMaintenanceConfig(): Observable<MaintenanceConfigUI> {
    this.init();

    let headers = this.defaultHeaders;

    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient
      .get<MaintenanceConfigUI>(`${this.baseUrl}/maintenance`, {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: 'body',
        reportProgress: false
      })
      .pipe(
        single(),
        map((_config: MaintenanceConfigUI) => {
          _config.creationDate = new Date();

          if (_config?.maintenance?.banner?.from) {
            _config.maintenance.banner.from = new Date(_config.maintenance.banner.from);
          }
          if (_config?.maintenance?.banner?.to) {
            _config.maintenance.banner.to = new Date(_config.maintenance.banner.to);
          }

          this.storeMaintenanceConfig(_config);
          return _config;
        }), catchError((error) => {
          throw Error(error);
        })
      );
  }

  /************************
   * LOCALSTORAGE REQUEST *
   ************************/

  storeMaintenanceConfig(maintenanceConfig: MaintenanceConfigUI) {
    localStorage.setItem(this.MAINTENANCE_LOCAL_ITEM, JSON.stringify(maintenanceConfig));
  }

  getMaintenanceConfigFromLocal(): MaintenanceConfigUI {
    return JSON.parse(localStorage.getItem(this.MAINTENANCE_LOCAL_ITEM));
  }

  deleteMaintenanceConfigFromLocal() {
    localStorage.removeItem(this.MAINTENANCE_LOCAL_ITEM);
  }
}
