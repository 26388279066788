import { Component, OnInit, ContentChild, TemplateRef, Input } from '@angular/core';
import { CheckListItemDirective } from './checklist-item/checklist-item.directive';

@Component({
  selector: 'pp-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.css']
})
export class ChecklistComponent implements OnInit {

  @Input() items: any[];

  @ContentChild(CheckListItemDirective, {read: TemplateRef, static:true}) itemTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }

}
