import { Directive, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: 'input[ppTogglePassword][type=password]'
})
export class TogglePasswordComponent implements OnInit {
  constructor(public elementRef: ElementRef, public translateService: TranslateService) { }

  ngOnInit() {
    const element = this.elementRef.nativeElement;

    const eye = document.createElement('i');
    eye.setAttribute('class', 'fa fa-eye form-control-feedback');
    eye.style.pointerEvents = 'all';
    eye.style.cursor = 'pointer';
    eye.style.position = 'absolute';
    eye.style.top = '35px';
    eye.style.right = '22px';

    element.setAttribute('placeholder',  this.translateService.instant('SHARED.COMPONENT.TOOGLE_PASSWORD.PLACEHOLDER'))

    eye.addEventListener('click', () => {
        if(element.getAttribute('type') === 'password') {
            element.setAttribute('type', 'text');
            eye.setAttribute('class', 'fa fa-eye-slash form-control-feedback');
        } else {
            element.setAttribute('type', 'password');
            eye.setAttribute('class', 'fa fa-eye form-control-feedback');
        }
    });

    element.parentNode.insertBefore(eye, element.nextSibling)
  }
}
