import { Component, ContentChild, OnInit } from '@angular/core';
import { InputCheckboxComponent } from './input-checkbox.component';

@Component({
  selector: 'pp-form-field-checkbox',
  templateUrl: './form-field-checkbox.component.html',
  styleUrls: ['./form-field-checkbox.component.css']
})
export class FormFieldCheckboxComponent implements OnInit {

  @ContentChild(InputCheckboxComponent, { static: true }) input! : InputCheckboxComponent;

  constructor() { }

  ngOnInit() {
  }

}
