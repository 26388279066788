import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public defaultLanguage: EventEmitter<string> = new EventEmitter();

  constructor(private translateService: TranslateService) { }

  getVariable(variableName: string, type?: TypeStorage) {
    const typeStorage = type === 'session' ? sessionStorage : localStorage;
    return typeStorage.getItem(variableName);
  }

  persistInBrowser(key: string, value: string, type?: TypeStorage) {
    const typeStorage = type === 'session' ? sessionStorage : localStorage;
    typeStorage.setItem(key, value);
  }

  getMonthsList() {
    // TODO: find a solution to get this on i18n json files
    if (this.translateService.getDefaultLang() === 'fr') {
      return ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
    } else if (this.translateService.getDefaultLang() === 'en') {
      return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    }
    return [];
  }
}

declare interface CustomKeycloakProfile extends Keycloak.KeycloakProfile {
  attributes: {locale: string[]};
}

declare type TypeStorage = 'session' | 'local';
