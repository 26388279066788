import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeEn);
registerLocaleData(localeEs)

/**
 * Return the date into the local format
 */
@Pipe({ name: 'localDate', pure: false })
export class LocalDatePipe implements PipeTransform {

  constructor(private translate: TranslateService, @Inject(LOCALE_ID) private locale: string) { }

  transform(value: Date, format?: string, locale?: string): string {

      const currentLang = this.translate.currentLang;

      if (value === null || value === undefined) {
        return '-';
      }

      if (currentLang === 'en') {
        if (format === 'dd/MM/yyyy HH:mm:ss') {
          format = 'MM/dd/yyyy HH:mm:ss';
        } else if (format === 'dd/MM/yyyy HH:mm') {
          format = 'MM/dd/yyyy HH:mm';
        } else if (format === 'dd/MM/yy') {
          format = 'MM/dd/yy';
        } else if (format === 'dd/MM/yyyy') {
          format = 'MM/dd/yyyy';
        } else if (format === 'd/M/yy') {
          format = 'M/d/yy';
        } else if (format === 'EEEE, dd LLLL, yyyy') {
          format = 'EEEE, LLLL dd, yyyy';
        }
      }

      switch (currentLang) {
        case 'fr':
          return (format != null && format !== undefined) ? formatDate(value, format, currentLang) : formatDate(value, "dd/MM/yyyy 'à' HH':'mm", currentLang);
        case 'en':
          return (format != null && format !== undefined) ? formatDate(value, format, currentLang) : formatDate(value, "MM/dd/yyyy 'at' HH':'mm", currentLang);
        case 'de':
          return (format != null && format !== undefined) ? formatDate(value, format, currentLang) : formatDate(value, "dd/MM/yyyy 'um' HH':'mm", currentLang);
        case 'es':
          return (format != null && format !== undefined) ? formatDate(value, format, currentLang) : formatDate(value, "dd/MM/yyyy 'a las' HH':'mm", currentLang);
        default:
          return "unsupported Language";
      }

  }

}
