import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AppAuthGuard extends KeycloakAuthGuard {
  constructor(protected router: Router, protected keycloakAngular: KeycloakService) {
    super(router, keycloakAngular);
  }
  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const isUrlExcluded = _.find(this.keycloakAngular.excludedUrls, urlExcluded =>
        state.url.match(urlExcluded.urlPattern)
      );
      if (!this.authenticated && !isUrlExcluded) {
        this.keycloakAngular.login();
        return;
      } else if (this.authenticated && state.url.startsWith('/users/registration')) {
        this.router.navigate(['/']);
        resolve(true);
      } else {
        resolve(true);
      }
    });
  }
}
