import { Component, OnInit } from '@angular/core';
import { FormFieldComponent } from '../form-field.component';

@Component({
  selector: 'pp-label',
  templateUrl: 'label.component.html'
})

export class LabelComponent implements OnInit {
  constructor(public formField: FormFieldComponent) { }

  ngOnInit() {
  }
}
