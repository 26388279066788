import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'operatorValue'
})
export class OperatorValuePipe  implements PipeTransform {
  transform(key: string): string {
    return environment.passenger.companies[key] || key;
  }
}
