import { Component, Directive, HostBinding, HostListener, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, NgControl, NgForm, RequiredValidator, Validators } from '@angular/forms';

@Directive({
  selector: '[ppInput]',
  host: {'[class.input-error]':'ngControl.touched && ngControl.invalid'}
})
export class InputComponent implements OnInit {

  @Input()
  get required(): boolean {
    if(this._required) {
      return true;
    } else {
      return hasRequiredField(this.ngControl.control);
    }
  }
  set required(value: boolean) {
    this._required = value;
  }

  _required = false;

  constructor(@Optional() @Self() public ngControl: NgControl) { }

  ngOnInit() {  }
}

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
      const validator = abstractControl.validator({}as AbstractControl);
      if (validator && validator.required) {
          return true;
      }
  }
  if (abstractControl['controls']) {
      for (const controlName in abstractControl['controls']) {
          if (abstractControl['controls'][controlName]) {
              if (hasRequiredField(abstractControl['controls'][controlName])) {
                  return true;
              }
          }
      }
  }
  return false;
};
