import { Component, ContentChild, OnInit } from '@angular/core';
import { InputComponent } from './input.component';

@Component({
  selector: 'pp-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.css']
})

export class FormFieldComponent implements OnInit {

  @ContentChild(InputComponent, { static: true }) input! : InputComponent;

  constructor() { }

  ngOnInit() { }
}
