import { Component, ViewChild } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface ConfirmModel {
  title?: string;
  message?: string;
  confirmButton: string;
  closeButton: string;
}

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {
  title: string;
  message: string;
  confirmButton: string;
  closeButton: string;

  constructor() {
    super();
  }

  confirm() {
    // we set modal result as true on clicking on confirm button, then we can get modal result from caller code
    this.result = true;
    this.close();
  }

  cancel() {
    this.result = false;
    this.close();
  }

}
