import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface SimpleInformationModel {
  title?: string;
  message?: string;
  closeButton?: string;
}

@Component({
  selector: 'app-simple-information-modal',
  templateUrl: './simple-information-modal.component.html',
  styleUrls: ['./simple-information-modal.component.css']
})
export class SimpleInformationModalComponent extends SimpleModalComponent<SimpleInformationModel,boolean> implements SimpleInformationModel {

  title?: string;
  message?: string;
  closeButton?: string;
  link?: string;

  constructor(private keycloakService: KeycloakService) { 
    super();
    this.link = this.keycloakService.getKeycloakInstance().createLoginUrl();
  }

  cancel() {
    this.result = true;
    this.close();
  }

}
