import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { UserDTO, UserCredentialsDTO } from '@app/core/model/models';
import { Configuration } from '../configuration';
import { environment } from '@env/environment';

@Injectable()
export class UserControllerService {

  protected basePath = '';
  protected urlKeyckloak = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient) {
    this.basePath = environment.passenger.api.mobilityEngine;
    this.urlKeyckloak =  environment.passenger.keycloak.url;
  }

  /**
   * Delete an user
     * Delete an user in the User Manager
     * @param userId User identifier to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUserUsingDELETE(userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUserUsingDELETE(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUserUsingDELETE(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUserUsingDELETE(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deleteUserUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/v1/users/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read an user by keycloak identifier
     * Read an user in the User Manager by keycloak identifier
     * @param realm Keycloak realm
     * @param userKeycloakId Keycloak identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readUserByKeycloakIdentifierUsingGET(realm: string, userKeycloakId: string, observe?: 'body', reportProgress?: boolean): Observable<UserDTO>;
    public readUserByKeycloakIdentifierUsingGET(realm: string, userKeycloakId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDTO>>;
    public readUserByKeycloakIdentifierUsingGET(realm: string, userKeycloakId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDTO>>;
    public readUserByKeycloakIdentifierUsingGET(realm: string, userKeycloakId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling readUserByKeycloakIdentifierUsingGET.');
        }

        if (userKeycloakId === null || userKeycloakId === undefined) {
            throw new Error('Required parameter userKeycloakId was null or undefined when calling readUserByKeycloakIdentifierUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserDTO>(`${this.basePath}/v1/realms/${encodeURIComponent(String(realm))}/reference/${encodeURIComponent(String(userKeycloakId))}/user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }



    /**
     * Update user credentials
     * Update user credentials in the User Manager
     * @param userCredentialsDTO User credentials to update
     * @param userId User credentials to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserCredentialsUsingPUT(userCredentialsDTO: UserCredentialsDTO, userId: number): Observable<void> {

        if (userCredentialsDTO === null || userCredentialsDTO === undefined) {
            throw new Error('Required parameter userCredentialsDTO was null or undefined when calling updateUserCredentialsUsingPUT.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling updateUserCredentialsUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<void>(`${this.basePath}/v1/users/${encodeURIComponent(String(userId))}/credentials`,
            userCredentialsDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers
            }
        );
    }


  public getAccessToken(grant_type: string, username: string, password: string): Observable<any> {

    const realm = environment.passenger.keycloak.realm;
    const client_id = environment.passenger.keycloak.clientId;
    if (grant_type === null || grant_type === undefined) {
      throw new Error('Required parameter grant_type was null or undefined when calling getAccessToken.');
     }

     if (username === null || username === undefined) {
      throw new Error('Required parameter username was null or undefined when calling getAccessToken.');
     }

     if (password === null || password === undefined) {
      throw new Error('Required parameter password was null or undefined when calling getAccessToken.');
     }

     let queryParameters = new HttpParams();
     queryParameters = queryParameters.set('client_id', <any>client_id);

     if (grant_type !== undefined && grant_type !== null) {
         queryParameters = queryParameters.set('grant_type', <any>grant_type);
     }
     if (username !== undefined && username !== null) {
         queryParameters = queryParameters.set('username', <any>username);
     }
     if (password !== undefined && password !== null) {
         queryParameters = queryParameters.set('password', <any>password);

     }

     let headers = this.defaultHeaders;
     headers = headers.set('Accept', 'application/json');
     headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');

     const body = 'grant_type='
                + grant_type + '&username='
                + encodeURIComponent(username) + '&password='
                + encodeURIComponent(password)
                + '&client_id=' + client_id;

    return this.httpClient.post<any>(`${this.urlKeyckloak}/realms/${encodeURIComponent(String(realm))}/protocol/openid-connect/token `,
     body,
    {
        headers: headers
    } );



  }

}
