import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import merge from 'lodash.merge';
import mergewith from 'lodash.mergewith';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TenantService } from './tenant/tenant.service';

@Injectable({providedIn: 'root'})
export class ConfigurationService {

  constructor(protected httpClient: HttpClient, protected translate: TranslateService, protected keycloakService: KeycloakService, private tenantService: TenantService) { }

  initConfiguration(): Observable<any> {
    function overrideArray(objValue: any, srcValue: any) {
      if (objValue && Array.isArray(objValue)) {
        return srcValue;
      }
    }

    return this.httpClient.get(`./assets/config/config.json?cacheBuster=${new Date().getTime()}`).pipe(tap(config => {
        merge(environment.passenger.sips, config.passenger.sips);
        mergewith(environment.passenger.multitenant, config.passenger.multitenant, overrideArray);
        mergewith(environment.passenger.application, config.passenger.application, overrideArray);
        mergewith(environment.passenger.settlements, config.passenger.settlements, overrideArray);
        mergewith(environment.passenger.customerRequest, config.passenger.customerRequest, overrideArray);
        mergewith(environment.passenger.journeys, config.passenger.journeys, overrideArray);
        mergewith(environment.passenger.companies, config.passenger.companies);
        mergewith(environment.passenger.api, config.passenger.api);
        merge(environment.ogone, config.ogone) 

        environment.passenger.keycloak = config.passenger.keycloak;
        
        // merchantMode is used in lower case by passenger-portal
        if (config.merchantMode) {
          environment.merchantMode = config.merchantMode.toLowerCase();
        }
    }));
  }

  fetchRemoteConfiguration(): Observable<any> {
    return this.tenantService.getPropertiesByTenant(environment.passenger.api.configServerUri).pipe(tap(config => {
      function overrideArray(objValue: any, srcValue: any) {
        if (objValue && Array.isArray(objValue)) {
          return srcValue;
        }
      }
      merge(environment, config, overrideArray);
      if (environment.merchantMode) {
        environment.merchantMode = environment.merchantMode.toLowerCase();
      }
    })
    );
  }

  /**
   * Init language for web app
   * Set lang for translate service
   */
  async initLanguage(): Promise<any> {
    this.translate.addLangs(environment.passenger.application.languages);
    const defaultLang = environment.passenger.application.languages[0] || 'fr';

    this.translate.setDefaultLang(defaultLang);
    try {
      const userProfile: CustomKeycloakProfile = await this.keycloakService.loadUserProfile();
      // In case there is no dropdown to select language
      if (userProfile.attributes.locale && environment.passenger.application.languages.includes(userProfile.attributes.locale[0])) {
        this.translate.use(userProfile.attributes.locale[0]);
      } else {
        this.translate.use(defaultLang);
      }
    } catch (_err) {
      this.translate.use(defaultLang);
    }
  }

  /**
   * Reload translations
   */
   async reloadTranslation(language: string): Promise<any> {
    this.translate.reloadLang(language).subscribe(() => {
      this.httpClient.get(`./assets/i18n/custom/${ language }.json?cacheBuster=${new Date().getTime()}`).subscribe(
        _translations => {
          this.translate.setTranslation(language, _translations, true)
          this.translate.use(language);
        });
    });
  }

  /**
   * Add custom translations
   */
   async addCustomTranslation(): Promise<any> {
    environment.passenger.application.languages.forEach(async lang => {
      let translations = await this.httpClient.get(`./assets/i18n/custom/${ lang }.json?cacheBuster=${new Date().getTime()}`).toPromise();
      this.translate.setTranslation(lang, translations, true);
    })
  }

}

declare interface CustomKeycloakProfile extends Keycloak.KeycloakProfile {
  attributes?: { locale: string[] };
}
