import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { AuthentificationService } from '@app/core/services/mobility-engine/authentification.service';
import { MobilityAccountResponse } from '@app/core/model/models';
import { AbstractOnDestroyComponent } from '@app/core/abstract-on-destroy-component/abstract-on-destroy-component';
import { environment } from '@env/environment';
import * as DOMPurify from 'dompurify';
import { Observable } from 'rxjs';
import { ConfigurationService } from '@app/core/services/configuration.service';

@Component({
  selector: '[app-navbar]',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent extends AbstractOnDestroyComponent implements OnInit {
  nextLanguage: string;
  currentLanguage: string;

  supportEnabled: boolean;
  externalSupport = false;
  externalSupportLink: string;

  loggedUser$: Observable<MobilityAccountResponse>;

  availableLanguages: string[] = [];
  showLang = false;

  constructor(
    private authentificationService: AuthentificationService,
    public location: Location,
    private router: Router,
    private keycloakService: KeycloakService,
    private translateService: TranslateService,
    private configurationService: ConfigurationService) {
    super();
  }

  ngOnInit() {
    // Init language
    this.currentLanguage = this.translateService.currentLang;
    this.availableLanguages = environment.passenger.application.languages;

    // Init loggedUser
    this.loggedUser$ = this.authentificationService.loggedUser$;

    // Init config support enabled
    this.supportEnabled = environment.passenger.customerRequest.enabled;
    if (this.supportEnabled && environment.passenger.customerRequest.mode === 'external') {
      this.externalSupport = true;
      this.externalSupportLink = DOMPurify.sanitize(environment.passenger.customerRequest.externalUrl);
    }

    // It's for respensive menu
    window.onload(null);

    // To scroll to up of the page when the URL changes.
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  logout() {
    this.keycloakService.logout().then(() => this.keycloakService.clearToken());
  }

  changeLanguage(language: string) {
    this.translateService.use(language);
    this.currentLanguage = this.translateService.currentLang;
    this.configurationService.reloadTranslation(this.currentLanguage).then(() => {});
    this.showLang = false;
  }

}
