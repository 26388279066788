import { Component, OnInit, NgModule, Input } from '@angular/core';
import { TapTypeEnum } from '@app/core/model/journey/enum/tap-type.enum';
import { TranslateIfExistPipe } from '@app/shared/pipes/translate-if-exist';
import { environment } from '@env/environment';

@Component({
  selector: 'pp-tap-details',
  templateUrl: './tap-details.component.html',
  styleUrls: ['./tap-details.component.css'],
  providers: [TranslateIfExistPipe],
})
export class TapDetailsComponent implements OnInit {

  @Input() serviceOperator_data: any;
  @Input() transportMode_data: any;
  @Input() vehicleReference_data: any;
  @Input() line_data: any;
  @Input() originStation_data: any;
  @Input() type_data: any;

  /**
   * Variables definition
   */
  transportMode = false;
  vehicle = false;
  line = false;
  origin = false;
  serviceOperator = false;
  transportModeSep = '';
  type = false;
  tapTypes = TapTypeEnum;
  display = false;

  constructor(private translateIfExistPipe: TranslateIfExistPipe) { }

  ngOnInit() {
    this.updateDataToDisplay();
  }

  /**
   * Update data with default value if exist to display or not
   */
  private updateDataToDisplay() {
    // Service operator
    if (this.serviceOperator_data) {
      if (environment.passenger.companies[this.serviceOperator_data]) {
        this.serviceOperator_data = environment.passenger.companies[this.serviceOperator_data];
      }
      if (environment.passenger.journeys.columns.includes('SERVICE_OPERATOR')) {
        this.serviceOperator = true;
      }
    }

    // Transport mode
    if (this.transportMode_data && environment.passenger.journeys.columns.includes('TRANSPORT_MODE')) {
      this.transportMode = true;
    }

    // Vehicle
    if (this.vehicleReference_data && environment.passenger.journeys.columns.includes('RAME')) {
      this.vehicle = true;
    }

    // Line
    if (this.line_data && environment.passenger.journeys.columns.includes('LINE')) {
      this.line_data = this.translateIfExistPipe.transform('ACTIVITY.LINE.' + this.line_data, this.line_data);
      this.line = true;
    }

    // Station
    if (this.originStation_data && environment.passenger.journeys.columns.includes('ORIGIN')) {
      this.origin = true;
    }

    // Type
    if (this.type_data && environment.passenger.journeys.columns.includes('TYPE')) {
      this.type = true;
    }

    // display separator
    if (this.serviceOperator || this.transportMode || this.vehicle || this.line || this.origin || this.type) {
      this.display = true;
    }
    if (this.transportMode || this.vehicle || this.line || this.origin || this.type) {
      this.serviceOperator_data += ' - ';
    }
    if (this.vehicle || this.line || this.origin || this.type) {
      this.transportModeSep = ' - ';
    }
    if (this.line || this.origin || this.type) {
      this.vehicleReference_data += ' - ';
    }
    if (this.origin || this.type) {
      this.line_data += ' - ';
    }
    if (this.type) {
      this.originStation_data += ' - ';
    }

  }

}
