export interface MaintenanceConfigUI {
  creationDate?: Date;
  maintenance?: MaintenanceConfig;
}

export interface MaintenanceConfig {
  enabled: boolean;
  banner: Banner;
  storage: Storage;
}

export interface Banner {
  enabled?: boolean;
  from?: Date;
  to?: Date;
}

export interface Storage {
  expiration: Expiration;
}

export interface Expiration {
  delay: string;
}

export enum MaintenanceStatus {
  INITIALIZE = 'INITIALIZE',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_ERROR = "IN_ERROR"
}