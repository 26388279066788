import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { MobilityAccountResponse } from '@app/core/model/models';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

  loggedUser$: BehaviorSubject<MobilityAccountResponse> = new BehaviorSubject(null);

  constructor(
    private keycloakService: KeycloakService,
    private httpClient: HttpClient
  ) { }

  /**
   * Get user from mobility account.
   * Send an event with the new data with loggedUse$ observer.
   *
   * @returns Observable<MobilityAccountResponse>
   */
  refreshLoggedUser(): Observable<MobilityAccountResponse> {
    const keycloakId = this.keycloakService.getKeycloakInstance().subject;
    const realm = this.keycloakService.getKeycloakInstance().realm;
    const baseUrl = environment.passenger.api.mobilityEngine;

    return this.httpClient.get<MobilityAccountResponse>(`${baseUrl}/v1/origin/${realm}/reference/${keycloakId}/mobilityaccount`).pipe(
        tap(user => {
          this.loggedUser$.next(user);
        })
      );
  }

}
